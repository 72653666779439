<template>
<div class="main">
  
  <div class="component-content">
      <Loading v-if="loading"/>

      <TopMenu class="mb-12p">
        <template v-slot:page-name>订单日历</template>
      </TopMenu>

      
          <div class="content bg-25 h-100">
           
              <DatePicker
                class="custom-calendar max-w-full"
                v-model="date"
                :masks="masks"
                :attributes="attributes"
                disable-page-swipe
                is-expanded
                @update:to-page="nextMonth"
              >
                <template v-slot:day-content="{ day, attributes }">
                  <div class="flex flex-col h-full z-10 overflow-hidden">
                    <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                    <div class="flex-grow overflow-y-auto overflow-x-auto shadow">
                      <div
                        v-for="attr in attributes"
                        :key="attr.key"
                        class="order-list"
                        @click="moveToPrint(attr.key)"
                      >
                        <div>{{ attr.customData.customer }}</div>
                        <div v-for="list in attr.customData.list" :key="list.id" class="text-gray-50 font-12">{{ list.name }}</div>
                      </div>
                    </div>
                  </div>
                </template>
              </DatePicker>
         
          </div>
        
  </div>

</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import Loading from '@/components/Loading.vue'
import { DatePicker } from 'v-calendar';

export default {
  name: 'OrderCalendar',

  data() {
    return {
      loading: false,
      date: null,
      masks: {
        weekdays: 'WWW',
      },
      attributes: [],
    };
  },

  activated() {
    this.$emit('sendMsg', {
      transition_name: 'up',
      app_name: this.$options.name
    })
    this.transition = 'none'
  },

  mounted() {
    console.log(this.$options.name+'挂载')

    this.getDatas(new Date())
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    Loading,
    TopMenu,
    DatePicker
  },

  methods: {
    moveToPrint(id) {
      console.log(id)
      this.$router.push({name: 'OrderPrint', params: {id: id}});
    },

    nextMonth(page) {
      let date = page.year +'-'+ page.month
      this.getDatas(date);
    },

    getDatas(date) {
      this.loading = true
      this.$axios.get('/api/order', {
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
        params: {
          date: date
        }
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data) {
            let datas = [];
            res.data.order.forEach(function(item) {
              datas.push({
                key: item.id,
                customData: {
                  customer: item.customer_nickname,
                  list: item.order_list
                },
                dates: item.deliver_at
              })
            })
            this.attributes = datas;
          }
          this.loading = false
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    showOrderList(data, index) {
      this.active = index
      this.orderList = data
    },

    handleMsg(e) {
      if (e.transition_name) {
        this.transition = e.transition_name
      }
    },
  },
  
}
</script>

<style lang="scss" scoped>

::v-deep .custom-calendar.vc-container {
  --day-border: 1px solid #383838;
  --day-border-highlight: 1px solid #383838;
  --day-width: 90px;
  --day-height: 120px;
  border-radius: 0;
  width: 100%;
  background: none;
  border: 1px solid #383838;
  & .vc-header {
    padding: 10px 0;
    & .vc-title {
      color: #fff;
    }
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    border-bottom: var(--day-border);
    padding: 5px 0;
  }
  & .vc-day {
    color: #7A7E86;
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    overflow: auto;
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
.order-list {
  color: #fff;
  font-size: 14px;
  padding: 6px;
  margin-bottom: 4px;
  background-color: #383838;
  border-radius: 4px;
  border-left: 4px solid #004bff;
  overflow: hidden;
}
</style>
